/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "app/shared/css/variables.scss";

* {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

.ra-bg-color {
  background-color: $ra-standard-dark-blue !important;
}

.ra-text-color {
  color: $ra-standard-dark-blue !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.error-text {
    color: red !important;
    font-size: small;
}

.space-validator,
.required-validator,
.max-length-validator,
.pattern-validator {
  margin-top: -1.5rem;
  font-size: 75%;
}

.flat-controls {
  .main-div {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
  }
}

.ra-blue-btn {
  background-color: $ra-standard-blue !important;
  color: white !important;
}

.mat-raised-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
  box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  max-height: 35px;
  min-width: 107px;
  letter-spacing: 1.4px;
}



.ui-disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
}

.ui-refreshing {
  pointer-events: none !important;
  opacity: 0.1 !important;
}

*[disabled] {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.fs-small {
  font-size: small !important;
}

.mat-select-trigger {
  height: auto !important;
}